// Third party
import React from "react";
import Formsy from "formsy-react";
import styled from "styled-components";
// Components
import Input from "../components/Input";
import Button from "../components/Button";

// Services
import { Subscribe } from "../services/mailchimp.service";

const Submit = Button.withComponent("button");

const Heading = styled.h2`
  font-size: 1.333rem;
`;
const Message = props => {
  const { title, message } = props;
  return (
    <div className="grid__col--3-of-3 grid__col">
      <h4>{title}</h4>
      <div dangerouslySetInnerHTML={{ __html: message }} />
    </div>
  );
};

const Fields = props => {
  return (
    <div>
      <div className="grid__col grid__col--3-of-3">
        <Heading>Meld je aan voor de Hormoon Yoga nieuwsbrief</Heading>
        <p>
          Blijf op de hoogte over de laatste ontwikkelingen van Hormoon Yoga,
          leuke tips en informatie over nieuwe workshops.
        </p>
      </div>
      <div className="grid__col--1-of-3 grid__col">
        <Input
          type="text"
          name="FNAME"
          id="mce-FNAME"
          placeholder="Je naam ..."
        />
      </div>
      <div className="grid__col--1-of-3 grid__col">
        <Input
          type="email"
          name="EMAIL"
          id="mce-EMAIL"
          validations="isEmail"
          validationError="Vul een geldig e-mailadres in"
          placeholder="Je email adres ... (Verplicht)"
          required
        />
      </div>
      <div className="grid__col--1-of-3 grid__col">
        <Submit
          primary
          id="mc-embedded-subscribe"
          type="submit"
          name="subscribe"
          disabled={!props.canSubmit}
        >
          Aanmelden
        </Submit>
      </div>
    </div>
  );
};

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.disableButton = this.disableButton.bind(this);
    this.enableButton = this.enableButton.bind(this);
    this.onChange = this.onChange.bind(this);
    this.submit = this.submit.bind(this);
    this.state = {
      canSubmit: false,
      submitSuccess: false,
      messageTitle: "",
      messageBody: "",
      pristine: true,
    };
  }

  disableButton() {
    this.setState({ canSubmit: false });
  }

  enableButton() {
    this.setState({ canSubmit: true });
  }

  onChange() {
    if (this.state.pristine) {
      window.dataLayer.push({
        event: "newsLetterFormInteract",
        eventCategory: "Newsletter",
        eventAction: "Type",
      });
    }
    this.setState({ pristine: false });
  }

  submitSuccess(response) {
    this.setState({
      submitSuccess: true,
      messageTitle:
        response.result === "success"
          ? "Je bent aangemeld"
          : "Er ging iets mis...",
      messageBody: response.msg || "You are subscribed!",
    });

    window.dataLayer.push({
      event: "newsLetterFormInteract",
      eventCategory: "Newsletter",
      eventAction: "submit",
      eventLabel: response.result,
    });
  }

  submitError() {
    this.setState({ submitSuccess: false });

    window.dataLayer.push({
      event: "newsLetterFormInteract",
      eventCategory: "Newsletter",
      eventAction: "submit",
      eventLabel: "error",
    });
  }

  submit(formData) {
    Subscribe(formData)
      .then(response => {
        this.submitSuccess(response);
      })
      .catch(error => {
        this.submitError();
      });
  }

  componentDidMount() {
    window.dataLayer = window.dataLayer || [];
  }

  render() {
    return (
      <Formsy
        onValidSubmit={this.submit}
        onValid={this.enableButton}
        onInvalid={this.disableButton}
        onChange={this.onChange}
      >
        <div className="grid">
          {this.state.submitSuccess ? (
            <Message
              title={this.state.messageTitle}
              message={this.state.messageBody}
            />
          ) : (
            <Fields canSubmit={this.state.canSubmit} />
          )}
        </div>
      </Formsy>
    );
  }
}

export default Form;
