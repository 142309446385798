const theme = {
  brandPrimaryLight: 'hsla(46, 98%, 76%, 1)',
  brandPrimary: 'hsla(46, 96%, 56%, 1)',
  brandPrimaryDark: 'hsla(46, 76%, 50%, 1)',
  
  brandSecondaryLight: 'hsla(146, 55%, 68%, 1)',
  brandSecondary: 'hsla(146, 46%, 48%, 1)',
  brandSecondaryDark: 'hsla(148, 38%, 25%, 1)',
  brandSecondaryDarkest: 'hsla(146, 24%, 19%, 1)',
  
  monoLightest: 'hsla(0, 0%, 100%, 0.9)',
  monoLight: 'hsla(0, 0%, 95%, 0.8)',
  mono: 'hsla(0, 0%, 50%, 0.6)',
  monoDark: 'hsla(0, 0%, 10%, 0.6)',
  monoDarkest: 'hsla(0, 0%, 0%, 0.9)'
}

export default theme;


// /* Coolors Exported Palette - coolors.co/adedc8-85ddab-42b373-28583e-253c2f */
// 
// /* HSL */
// $color1: hsla(145%, 64%, 80%, 1);
// $color2: hsla(146%, 56%, 69%, 1);
// $color3: hsla(146%, 46%, 48%, 1);
// $color4: hsla(148%, 38%, 25%, 1);
// $color5: hsla(146%, 24%, 19%, 1);
// 
// /* RGB */
// $color1: rgba(173, 237, 200, 1);
// $color2: rgba(133, 221, 171, 1);
// $color3: rgba(66, 179, 115, 1);
// $color4: rgba(40, 88, 62, 1);
// $color5: rgba(37, 60, 47, 1);