/**
 * fetch workshop data from endpoint
 */

const endpoint = "/.netlify/functions/workshop";

function getWorkshops() {
  return fetch(endpoint)
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      return error;
    });
}

export { getWorkshops };
