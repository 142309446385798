import styled from "styled-components";
import theme from "../theme";

const Button = styled.a`
  background-color: ${props =>
    props.primary ? theme.brandPrimary : theme.brandSecondaryDark};
  border-radius: 4px;
  border: 1px solid
    ${props =>
      props.primary ? theme.brandPrimaryDark : theme.brandSecondaryDark};
  color: ${props => (props.primary ? theme.monoDark : theme.monoLight)};
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  line-height: 1.45;
  height: 50px;
  padding: 0.8em ${props => (props.hero ? "4em" : " 1.45em")};
  margin: 0;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  touch-action: manipulation;
  transition: background-color 0.3s, color 0.3s;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  &:hover,
  &:focus,
  &:active {
    color: ${props => (props.primary ? theme.monoDarkest : theme.monoLightest)};
    background-color: ${props =>
      props.primary ? theme.brandPrimaryDark : theme.brandSecondaryDark};
  }
  &:disabled {
    color: ${theme.monoDarkest};
    background-color: ${theme.monoLightest};
  }
  @media (max-width: 480px) {
    padding: 0.75em ${props => (props.hero ? "2em" : " 1.25em")};
  }
`;

export default Button;
