import React from 'react'
import styled from 'styled-components';

const Element = styled.div`
	margin-right: auto;
	margin-left: auto;
	padding-left: 15px;
	padding-right: 15px;
	padding-bottom: 1px;
	text-align: ${props => props.centered ? 'center' : 'left'};

	@media (min-width: 768px) {
		width: 750px;
	}
	@media (min-width: 992px) {
		width: 970px;
	}
	@media (min-width: 1200px) {
		width: 1170px;
	}
`;

export default Element;