import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Button from "../components/Button";
import Card from "../components/Card";

moment.locale("nl", {
  months: [
    "januari",
    "februari",
    "maart",
    "april",
    "mei",
    "juni",
    "juli",
    "augustus",
    "september",
    "oktober",
    "november",
    "december",
  ],
  monthsParseExact: true,
  weekdays: [
    "zondag",
    "maandag",
    "dinsdag",
    "woensdag",
    "donderdag",
    "vrijdag",
    "zaterdag",
  ],
  weekdaysParseExact: true,
});
const EventItem = (props) => {
  const { name, start, end, url, capacity = 0, summary, count, venue } = props;
  const col = `grid__col--1-of-${count}`;
  return (
    <div className={col + " grid__col"}>
      <Card>
        <h4>{name.text}</h4>
        <p>
          <strong>
            {moment(start.local).format("dddd, MMMM Do")},{" "}
            {moment(start.local).format("h:mma")} -{" "}
            {moment(end.local).format("h:mma")}
          </strong>
          <br />
          {venue.name}
        </p>
        <p>{summary}</p>
        <p>
          <strong>Beschikbaar</strong>: {capacity}
        </p>
        <Button primary href={url}>
          Meer info
        </Button>
      </Card>
    </div>
  );
};

export default EventItem;

EventItem.propTypes = {
  name: PropTypes.shape({
    text: PropTypes.string,
  }),
  start: PropTypes.shape({
    local: PropTypes.string,
  }),
  end: PropTypes.shape({
    local: PropTypes.string,
  }),
  url: PropTypes.string,
  capacity: PropTypes.number,
  count: PropTypes.number,
};
