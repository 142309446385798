import Link from "gatsby-link";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import Container from "../components/Container";
import GenericItem from "../components/GenericItem";
import Section from "../components/Section";

import WorkshopContainer from "../containers/Workshop";
// Forms
import Newsletter from "../forms/Newsletter";
import theme from "../theme";

const FooterSection = styled(Section)`
  background-color: ${theme.brandSecondaryDarkest};
  padding-top: 30px;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${theme.monoLightest};
  }
  p {
    color: ${theme.monoLight};
  }
`;

const Footer = (props) => {
  const {
    site: {
      siteMetadata: { title },
    },
    allContentfulPost: { edges: posts },
    allContentfulPage: { edges: pages },
    allContentfulTestimonial: { edges: testimonials },
  } = props;
  return (
    <div>
      <Section inverted>
        <Container centered>
          <h2>De Hormoonyoga serie leer je zo</h2>
          <p>
            De Hormoonyoga serie kun je leren in zijn geheel in een 1 op 1
            sessie of een workshop op aanvraag. Ook kun je met 2 of meer dames
            tegelijk boeken voor een Hormoon Yoga les.
          </p>
          <p>
            Tijdens een eerste privé les Hormoon Yoga behandelen we de hele
            serie in minimaal 2 uur voor een eerste kennismaking (vervolglessen
            kunnen 1 uur duren). Een privéles Hormoon Yoga is mogelijk in Yoga
            ruimte in Woerden of op eigen locatie.
          </p>
          <p>
            Je kunt Hormoon Yoga ook komen volgen als privéles. Voor een
            afspraak kun je contact opnemen via{" "}
            <a href="tel:+31628702346">+31 (0) 6 2870 2346</a> of{" "}
            <a href="mailto:info@hormoonyoga.nl">info@hormoonyoga.nl</a>
          </p>
        </Container>
        <Container centered>
          <h2>Workshops</h2>
          <WorkshopContainer />
        </Container>
      </Section>
      <Section inverted>
        <Container>
          <Newsletter />
        </Container>
      </Section>
      <FooterSection inverted>
        <Container>
          <div className="grid">
            <div className="grid__col grid__col--4-of-12">
              <h6>Algemeen</h6>
              <ul>
                {pages.map((item) => (
                  <GenericItem {...item} key={item.node.id} />
                ))}
              </ul>
            </div>
            <div className="grid__col grid__col--4-of-12">
              <h6>Blog</h6>
              <ul>
                {posts.map((item) => (
                  <GenericItem {...item} key={item.node.id} segment="blog" />
                ))}
              </ul>
            </div>
            <div className="grid__col grid__col--4-of-12">
              <h6>Testimonials</h6>
              <ul>
                {testimonials.map((item) => (
                  <GenericItem
                    {...item}
                    key={item.node.id}
                    segment="testimonials"
                  />
                ))}
              </ul>
            </div>
          </div>
        </Container>
      </FooterSection>
      <FooterSection>
        <Container>
          <div className="grid">
            <div className="grid__col grid__col--12-of-12">
              <p style={{ textAlign: "center" }}>
                <span>&copy; {moment().format("YYYY")} </span>
                <Link to="/">{title}</Link>
                <span> - All rights reserved.</span>
              </p>
            </div>
          </div>
        </Container>
      </FooterSection>
    </div>
  );
};

export default Footer;

Footer.propTypes = {
  title: PropTypes.string
};
