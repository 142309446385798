// Third party
import React from 'react';
import { withFormsy } from 'formsy-react';
import styled from 'styled-components'

// Theme
import theme from '../theme';

const Element = styled.input`
	border: 1px solid ${theme.brandSecondary};
	border-radius: 3px;
	display: inline-block;
	line-height: 1;
	font-size: 18px;
	padding: 0.75em 16px;
	width: 100%;
	&:disabled {
		border-color: ${theme.monoLight};
	}
`

const Wrapper = styled.div`
	margin-bottom: 15px;
	position: relative;
`

const Tooltip = styled.div`
	animation: bounce 2s ease-out infinite;
	background-color: ${ theme.brandPrimary };
	border-radius: 3px;
	color: ${ theme.monoLightest };
	font-size: 1em;
	padding: 0.25em 0.75em;
	left: 5px;
	position: absolute;
	top: -30px;
	opacity: .95;
	@keyframes bounce {
	  0% {
			transform:translateY(0);
	  }
		25% {
	  	transform:translateY(-10%);
	  }
	  50% {
	  	transform:translateY(0);
	  }
		75% {
	  	transform:translateY(-10%);
	  }
	  100% {
	  	transform:translateY(0);
	  }
	}
`

class Input extends React.Component {
	constructor(props) {
		super(props);
		this.changeValue = this.changeValue.bind(this);
	}
	
	changeValue(event) {
		// setValue() will set the value of the component, which in
    // turn will validate it and the rest of the form
    // Important: Don't skip this step. This pattern is required
    // for Formsy to work.
		this.props.setValue(event.currentTarget.value);
	}
	
	render() {
		return (
			<Wrapper>
				<Element
					onChange={ this.changeValue }
					type={ this.props.type }
					id={ this.props.id }
					name={ this.props.name }
					value={ this.props.getValue() || '' }
					placeholder={ this.props.placeholder }
					disabled={ !!this.props.disabled }
				/>
				{this.props.getErrorMessage() && <Tooltip>{ this.props.getErrorMessage() }</Tooltip> }
			</Wrapper>
		)
	}
}

export default withFormsy(Input);