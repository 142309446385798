import React from 'react'
import styled from 'styled-components';

import theme from '../theme'

const Element = styled.div`
	padding: 50px 0;
	background-color: ${props => props.inverted ? theme.monoLight : theme.monoLightest};
	h1, h2, h3, h4, h5, h6 {
    color: ${theme.monoDarkest};
  }
	p {
		color: ${theme.monoDark};
	}
`

export default Element;