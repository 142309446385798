import React from 'react'
import styled from 'styled-components'

import theme from '../theme'

const Card = styled.div`
  background-color: ${theme.monoLightest};
  border-top: 6px solid ${theme.brandSecondary};
  border-radius: 4px;
  text-align: left;
  margin: 0 0 20px;
  padding: 10px 10px 20px;
  @media (min-width: 768px) {
    padding: 10px 20px 40px;
  }
`;
export default Card;
