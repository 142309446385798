// Third party
import React from 'react'
import EventItem from '../components/EventItem'

import { getWorkshops } from '../services/workshop.service'

class WorkshopContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      workshops: [],
      workshopCount: 0,
      error: false,
    };
  }

  componentDidMount() {
    this.setState({ isLoaded: false });
    getWorkshops()
      .then((data) => {
        // guard
        if (Array.isArray(data) === true) {
          this.setState({
            isLoaded: true,
            workshops: data,
            workshopCount: data.length,
          });
        } else {
          throw new Error("Error loading Workshop");
        }
      })
      .catch((error) => {
        this.setState({
          isLoaded: true,
          error: true,
        });
      });
  }

  render() {
    if (this.state.error === true) {
      return <p>Er ging iets mis bij het laden van de workshops...</p>;
    }

    if (this.state.isLoaded === false) {
      return <p>Bezig met het laden van de workshops...</p>;
    }
    if (this.state.workshopCount === 0) {
      return (
        <p>
          Er zijn op dit moment geen workshops gepland. Abonneer je op de
          nieuwsbrief om op de hoogte te blijven.
        </p>
      );
    }

    return (
      <div className="grid">
        {this.state.workshops.map((workshop) => (
          <EventItem
            key={workshop.id}
            count={this.state.workshopCount}
            {...workshop}
          />
        ))}
      </div>
    );
  }
}

export default WorkshopContainer;
