// Third party
import queryString from 'query-string'

import request from '../utils/request'

const config = {
  u: '505ecd9fdc886b3d620c71cb4',
  id: 'c42d437fb6',
}

const endpoint = 'https://hormoonyoga.us11.list-manage.com/subscribe/post-json'

const Subscribe = formData => {
  if (formData === null || typeof formData !== 'object') {
    throw new Error('Invalid argument')
  }
  const dataString = queryString.stringify(Object.assign({}, config, formData))
  return new Promise((resolve, reject) => {
    request(`${endpoint}?${dataString}`)
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export { Subscribe }
