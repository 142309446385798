import fetchJsonp from 'fetch-jsonp';

export default function request (path) {
	return new Promise((resolve, reject) => {
		fetchJsonp(path, {
			jsonpCallback: 'c'
		})
		.then(function (response) {
			return response.json()
		})
		.then(function (json) {
			resolve(json)
		})
		.catch(function (ex) {
			reject(ex);
		})
	})
};