import React from 'react'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'

const GenericItem = props => {
  const { 
    node : {
      id,
      title,
      slug = '',
    },
    segment
  } = props;
  const path = segment ? `/${segment}/${slug}` : `/${slug}`;
  return (
    <li>
      <Link to={path}>{title}</Link>
    </li>
  )
}

export default GenericItem

GenericItem.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  slug: PropTypes.string,
}
