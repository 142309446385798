import React from 'react'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'
import styled from 'styled-components';

const Menu = styled.ul`
  float: right;
  margin: 0;
  margin-right: -15px;
  @media (max-width: 480px) {
		display: none;
	}
`;

const MenuItem = styled.li`
  float: left;
  display: block;
  margin: 0;
  padding: 0 10px;
`;

const NavBar = (props) => {
  const { items } = props;
  return (
    <Menu>
      {items.map(item => (
        <MenuItem key={item.id}>
         <Link to={item.url}>{item.title}</Link>
        </MenuItem>
      ))}
    </Menu>
  )
}

export default NavBar;

NavBar.propTypes = {
  items: PropTypes.array
}